input {
    &[type=checkbox], &[type=radio]  {
    
            margin-top: 6px;
            margin-right: 5px;
            border-color: var(--nature);
            border-radius: 2px;
     
          
          &:checked[type='checkbox'] {
            border-color: var(--nature);
            background-color: var(--nature);
          }
          
          &:focus {
            border-color: var(--nature);
            box-shadow: none;
          }
    
    
    
    }
    
}
