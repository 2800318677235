@import "../../styles/mixins";


.homepage_wrapper {

  .temp_logo {
    width: 174px;
    height: 41px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100%;


  .hero_section {
    padding: 62px 15px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include tablet-small-bp {
      padding: 36px 15px 42px;
      background-color: #FCF7F2;
    }

    .hero_section_title {
      font-weight: 400;
      font-size: 84px;
      line-height: 110%;
      /* or 92px */
      text-align: center;
      letter-spacing: -0.02em;
      margin-bottom: 22px;
      max-width: 952px;
      @include customMedia("950px") {
        font-size: 64px;
      }
      @include tablet-small-bp {
        font-size: 54px;
        margin-bottom: 34px;
      }
    }

    .hero_section_description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      max-width: 420px;
      margin-bottom: 40px;
      @include tablet-small-bp {
        margin-bottom: 56px;
      }
    }

    .hero_section_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      position: relative;

      .btn {
        padding: 9px 40px;
      }

      @include tablet-small-bp {
        gap: 15px;
        flex-direction: column;
        > a {
          display: block;
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: calc(100% + 96px);
        bottom: -10px;
        width: 90px;
        height: 90px;
        background-image: url("../../assets/img/hero_section_detail.svg");
        background-position: center;
        background-repeat: no-repeat;
        @include customMedia("950px") {
          @include Hide;
        }
      }
    }

    .hero_section_mobile_strip {
      display: none;
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 54px;
      justify-content: space-between;
      align-items: center;
      z-index: 999;
      @include tablet-small-bp {
        display: flex;
        background-color: #fff;
        padding: 44px 34px;
      }

      .hero_section_mobile_strip_icon {
        width: 42px;
        height: 42px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(1) {
          background-color: #4066B7
        }

        &:nth-child(2) {
          background-color: #1EA6C4
        }

        &:nth-child(3) {
          background-color: #F5C84F
        }

        &:nth-child(4) {
          background-color: #10213E
        }
      }
    }

  }

  .offer_section {
    background-color: #FEF9F4;
    width: calc(100% - 92px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px 0 350px;
    @include customMedia("1440px") {
      padding: 32px 0 250px;
    }
    @include customMedia("950px") {
      width: calc(100% - 40px);
    }
    @include tablet-small-bp {
      padding: 32px 14px 24px;
      width: 100%;
    }

    .offer_section_image {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      margin-bottom: 76px;
      @include tablet-small-bp {
        @include Hide;
      }
    }

    .offer_section_title {
      text-align: center;
      @include tablet-small-bp {
        font-size: 54px;
      }
    }

    .offer_section_actions {
      margin-top: 87px;
      width: calc(100% - 104px);
      max-width: 1242px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include tablet-small-bp {
        width: 100%;
        margin-top: 52px;
      }

      .offer_section_actions_row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        justify-content: center;
        gap: 34px;

        @include customMedia("1440px") {
          grid-template-columns: repeat(5, 1fr);
        }
        @include customMedia("1200px") {
          grid-template-columns: repeat(3, 1fr);
          width: calc(100% - 54px);
        }
        @include customMedia("950px") {
          grid-template-columns: repeat(2, 1fr);
          gap: 26px;
          width: calc(100% - 13px);
        }


        .offer_section_cta {
          background: #FFFFFF;
          border-radius: 20px;
          padding: 20px 10px 20px 20px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          transition: 200ms all ease-in-out;
          position: relative;
          height: 100%;
          width: 100%;
          @include tablet-small-bp {
            padding: 12px;
          }

          &:hover, &:focus {
            transition: 200ms all ease-in-out;
            background-color: #4066B7;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);

            .offer_section_cta_title {
              transition: inherit;
              color: #fff;
            }

            .offer_section_cta_desc {
              transition: inherit;
              color: #FFFFFF;
              opacity: 0.5;

            }
          }


          @include customMedia("1200px") {
            &:nth-child(6), &:nth-child(7), &:nth-child(8) {
              grid-column-start: auto !important;
            }
          }

          &:nth-child(6) {
            grid-column-start: 2;
          }

          &:nth-child(7) {
            grid-column-start: 3;
          }

          &:nth-child(8) {
            grid-column-start: 4;
          }


          .offer_section_cta_logo {
            width: 42px;
            height: 42px;
            margin-bottom: 16px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;

            > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .offer_section_cta_title {
            font-weight: 400;
            font-size: 21px;
            line-height: 140%;
            color: #000000;
            margin-bottom: 10px;
            @include tablet-small-bp {
              font-size: 16px;
            }
          }

          .offer_section_cta_desc {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #999999;
            @include tablet-small-bp {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .app_overview_section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px 0 0;
    margin-bottom: 84px;

    @include tablet-small-bp {
      background-image: url("../../assets/img/app_overview_detail_1.svg"), url("../../assets/img/hero_section_detail.svg");
      background-position: 0 240px, calc(100% - 20px) 129px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-size: 170px, 60px;
      padding-top: 80px;
    }

    .app_overview_section_first_row {
      width: calc(100% - 92px);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 80px;
      position: relative;
      @include tablet-small-bp {
        width: calc(100% - 62px);
      }

      .app_overview_section_first_row_column {
        position: relative;
        flex: 1;
        margin-bottom: 64px;
        @include tablet-small-bp {
          margin-bottom: 104px;
          &:nth-child(2) {
            @include Hide
          }
        }

        > h2 {
          margin-bottom: 20px;
          max-width: 494px;
          @include tablet-bp {
            font-size: 36px;
          }
          @include tablet-small-bp {
            font-size: 32px;
            max-width: 100%;
          }
        }

        > p {
          margin-bottom: 20px;
          font-size: 16px;
          max-width: 494px;
          @include tablet-small-bp {
            max-width: 100%;
            margin-bottom: 80px;
          }
        }

        > ul {
          list-style: none;
          padding: 0;

          li {
            &:nth-child(1):before {
              content: "1.";
              color: #1ea6c4;
              margin-right: 4px;
            }

            &:nth-child(2):before {
              content: "2.";
              color: #79d2cc;
              margin-right: 4px;
            }

            &:nth-child(3):before {
              content: "3.";
              color: #f5c84f;
              margin-right: 4px;
            }

            &:nth-child(4):before {
              content: "4.";
              color: #ec5b78;
              margin-right: 4px;
            }
          }
        }

        > img {
          position: absolute;
          width: 100%;
          /* top: -300px; */
          bottom: -460px;
          @include customMedia("1440px") {
            position: relative;
            bottom: auto;
          }
          @include tablet-small-bp {
            @include Hide;
          }
        }
        .phone_images {
          margin-top: -245px;
          margin-left: -40px;
        }
      }
    }

    .app_overview_section_second_row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 100px;
      margin-bottom: 88px;
      @include tablet-small-bp {
        flex-direction: column-reverse;
        width: calc(100% - 62px);
        gap: 32px;
      }

      .app_overview_section_second_row_column {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 { 
          margin-bottom: 20px;
          max-width: 493px;
          width: 100%;
          @include tablet-bp {
            font-size: 36px;
          }
          @include tablet-small-bp {
            font-size: 32px;
            max-width: 100%;
            text-align: left;
          }
        }

        > p {
          margin-bottom: 20px;
          font-size: 16px;
          max-width: 493px;
          @include tablet-small-bp {
            max-width: 100%;
            text-align: left;
          }
        }

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .app_overview_section_third_row {
      width: calc(100% - 92px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include tablet-small-bp {
        width: calc(100% - 62px);
      }

      > h2 {
        margin-bottom: 20px;
        @include tablet-bp {
          font-size: 36px;
        }
        @include tablet-small-bp {
          font-size: 32px;
          margin-bottom: 25px;
        }
      }

      > p {
        @include tablet-small-bp {
          margin-bottom: 32px;
        }
      }

      > img {
        width: 100%;
      }
    }
  }

  @mixin goal-section($bg-image) {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #FEF9F4;
    gap: 80px;
    padding: 120px 0 120px 0;
    background-image: url($bg-image);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
  
    @include tablet-small-bp {
      background-image: none;
      padding: 46px 0 0;
      gap: 0;
    }
  
    .our_goal_section_container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: calc(100% - calc(100vw / 3));
  
      @include tablet-small-bp {
        max-width: calc(100% - 62px);
      }
  
      .our_goal_section_column {
        flex: 1;
  
        @include tablet-small-bp {
          margin-bottom: 50px;
  
          &:nth-child(2) {
            @include Hide;
          }
        }
  
        > h2 {
          margin-bottom: 26px;
  
          @include tablet-bp {
            font-size: 36px;
          }
  
          @include tablet-small-bp {
            font-size: 46px;
          }
        }
  
        > p {
          font-size: 16px;
          margin-bottom: 20px;
        }
  
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .our_goal_section_fr {
    @include goal-section("../../assets/img/fr/our_goal_app_image_fr.png");
  }

  .our_goal_section_en {
    @include goal-section("../../assets/img/en/our_goal_app_image_en.png");
  }

  .recommendation_section {
    position: relative;
    width: 100%;
    overflow: hidden;

    @include tablet-small-bp {
      background-color: #FEF9F4;
    }

    .carousel {
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 0;

      .carousel_inner {
        width: 100%;
        overflow: hidden;
        position: relative;

        .carousel_items {
          width: 100%;
          height: 100%;
          display: block;
          text-align: left;
          white-space: nowrap;
          transition: .2s all ease-in-out;
          overflow-x: auto;
          overflow-y: hidden;
          scroll-behavior: smooth;
          padding: 64px 0 100px;

          &::-webkit-scrollbar {
            display: none;
          }

          scrollbar-width: none;

          .carousel_item {
            height: 100%;
            width: 538px;
            display: inline-block;
            overflow: hidden;
            margin: 0 24px;
            vertical-align: top;
            background: #FFFFFF;
            box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.54);
            @include tablet-small-bp {
              width: 320px;
              margin: 0 15px;
            }

            &:first-child {
              margin-left: 52px;
              @include tablet-small-bp {
                margin-left: 30px;
              }
            }

            &:last-child {
              margin-right: 52px;
              @include tablet-small-bp {
                margin-right: 30px;
              }
            }

            .carousel_item_subtitle {
              padding-top: 32px;
              margin-bottom: 6px;
              display: block;
              text-align: center;
              @include tablet-small-bp {
                font-size: 12px;
              }
            }

            .carousel_item_title {
              display: block;
              color: #fff;
              font-weight: 400;
              font-size: 54px;
              line-height: 110%;
              /* or 59px */
              text-align: center;
              margin-bottom: 25px;
              @include tablet-small-bp {
                font-size: 32px;
              }
            }

            .carousel_item_image {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 320px;
              @include tablet-small-bp {
                height: 200px;
              }

              > img {
                height: 100%;
              }
            }

            .carousel_item_desc {
              padding: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #000;
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
              background-color: #fff;
              white-space: pre-wrap;
              @include tablet-small-bp {
                padding: 36px;
              }
            }
          }
        }
      }
    }
  }

  .app_ad_section {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #FEF9F4;
    gap: 188px;
    @include customMedia("1200px") {
      padding: 0 15px;
      gap: 80px;
      align-items: center;
    }
    @include customMedia("600px") {
      gap: 24px;
      flex-direction: column;
      padding-top: 90px;
    }

    .app_ad_section_column {
      display: flex;
      flex-direction: column;
      max-width: 490px;

      > img {
        width: 100%;
        margin-bottom: 20px;
        max-width: 418px;
        margin: 0 auto;
        
        @include customMedia("950px") {
        }
      }

      > h2 {
        margin-bottom: 18px;
        text-align: center;
        @include customMedia("950px") {
          font-size: 44px;
        }
      }

      > p {
        margin-bottom: 60px;
        text-align: center;
        @include customMedia("950px") {
          font-size: 14px;
        }
      }
    }
  }

}

