@import "../mixins";


.language_dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  @include tablet-small-bp {
    order: 4;

  }

  .dropdown_toggle_button {
    flex-shrink: 0;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #fff;
    background: #F5C84F;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    text-transform: uppercase;
    border: none;
    @include tablet-small-bp {
      border-radius: 50px;
      height: 33px;
      width: 33px;
      padding: 0;
      place-content: center;
    }

    > span {
      white-space: nowrap;
    }

    &_active {
      background: #F5C84F;
      color: #fff;
    }

    > i {
      @include tablet-small-bp {
        @include Hide;
      }
      margin-left: 12px;
    }
  }

  .dropdown_menu {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    padding: 4px 0;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.04);
    list-style: none;
    display: flex;
    flex-direction: column;
    z-index: 9000;
    gap: 4px;
    @include tablet-small-bp {
      top: auto;
      bottom: calc(100% + 2px);
    }

    .dropdown_menu_item {
      font-size: 14px;
      line-height: 24px;
      color: #000;
      margin: 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .dropdown_menu_item_link {
        text-decoration: none;
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        width: 100%;
        padding: 0;
        margin: 0;
        place-content: center;

        &:hover {
          background-color: #D4D4D4;
        }

        .dropdown_menu_item_link_icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          object-fit: contain;
          font-size: 4px;
          margin-right: 12px;
          padding: 2px;
          background-color: #000;
        }
      }
    }
  }
}

.footer {
  background: #FFFFFF;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px 20px 0 0;
  max-width: 1312px;
  position: relative;
  margin: 0 auto;
  width: calc(100% - 32px);
  &.homepage_footer {
    @include tablet-small-bp {
      margin-bottom: 120px;
    }
  }

  .desc {
    max-width: 250px;
    opacity: 0;
  }
  .footer_container {
    display: flex;
    padding: 77px 56px;
    @include customMedia("900px") {
      flex-direction: column;
    }
    @include tablet-small-bp {
      padding: 44px 30px;
    }

    .footer_left {
      width: 25%;
      flex: 1;
      @include tablet-small-bp {
        width: 100%;
        flex-direction: row;
        display: flex;
        gap: 40px;
      }
    }

    @include customMedia("900px") {
      width: 100%;
    }

    .footer_desktop_footer {
      margin-bottom: 46px;
      @include tablet-small-bp {
        @include Hide;
      }
    }

    .footer_mobile_footer {
      display: none;
      @include tablet-small-bp {
        display: block;
      }
    }

    > img {
      margin-bottom: 46px;
      max-width: 250px;
    }

    > p {

    }
  }

  .footer_right {
    width: 75%;
    flex: 1;
    display: flex;
    padding-top: 80px;
    @include customMedia("900px") {
      width: 100%;
    }
    @include tablet-small-bp {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding-top: 66px;
      grid-row-gap: 50px;
    }

    .footer_links {
      flex: 1;
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-bottom: 0;
      @include tablet-small-bp {
        gap: 5px;
        &:nth-child(1) {
          order: 3
        }
        &:nth-child(2) {
          order: 1
        }
        &:nth-child(3) {
          order: 2
        }
      }

      > li {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;

        &:first-child {
          font-weight: 400;
          font-size: 21px;
          line-height: 140%;
        }

        > a {
          all: inherit;
          cursor: pointer;
        }
      }
    }
  }

  .footer_controls {
    position: absolute;
    top: 32px;
    right: 30px;
    display: flex;
    align-items: center;
    gap: 12px;
    @include tablet-small-bp {
      position: relative;
      top: auto;
      right: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      order: 4;
    }

    .footer_controls_social {
      background-color: #79D2CC;
      border-radius: 50px;
      height: 33px;
      width: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

