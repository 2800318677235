.patient_profile_page {
    padding: 20px 0;
    
    .img {
        max-width: 168px;
        padding: 24px;
        background: #fff;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .edit_img_btn {
        color: #1EA6C4;
        cursor: pointer;
    }
    
    @include phone-bp  {

    }
}