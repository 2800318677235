@import "../../styles/mixins";


.offer_wrapper {
  display: block;
  width: 100%;
  .offer {
    position: relative;
    margin: 15px 0;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 20px;
    height: 220px;
    overflow: hidden;
    flex-direction: column;
    transition: 200ms all ease-in-out;
    &:hover, &:focus {
      background-color: #4066B7;
      transition: 200ms all ease-in-out;
      .offer-info {
        > h3 {
          color: #fff;
        }
        > p {
          color: #fff;
        }
      }
    }
    > a {
      display: block;
      width: 100%;
    }
    .offer-info {
      height: 130px;
      > p {
        margin: 0;
        color: #999999;
      }
    }
    .offer-icon {
      position: relative;
      width: 42px;
      height: 42px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: var(--darkblue);
      &.dashboard {
        background-color: var(--flowers);
      }
      > a {
        color: #000;
      }
      > p {
        margin: 0;
        color: #999999;
      }
    }
  }

}

.offer .offer-icon.calendar {
  background-color: var(--sun);
}

.offer .offer-icon.wallet {
  background-color: var(--nature);
}

.offer .offer-icon.chart {
  background-color: var(--sunset);
}

.offer .offer-icon.personal {
  background-color: var(--flowers);
}

.offer .offer-icon.document {
  background-color: var(--sun);
}

.offer .offer-icon.settings {
  background-color: var(--ocean);
}

.offer .offer-icon.settings {
  background-color: var(--ocean);
}

.offer .offer-icon.privacy {
  background-color: var(--nature);
}

.notification-count {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 23px;
  height: 23px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background-color: var(--sunset);
}
