.doctor_profile {
 
    padding: 80px 0;

    .img {
        max-width: 168px; 
        padding: 24px;
        background: #fff;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .edit_img_btn {
        color: #1EA6C4;
        cursor: pointer;
    }
    .desc {
        margin-top: 30px;
        margin-bottom: 50px;
    }


    .third_party_buttons {
        display: flex;
    }
    .form-check {
        margin-right: 30px;
    }
    @include phone-bp  {

    }
}