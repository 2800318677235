 /* MINI EVENT */
      
 .mini-event-container {
    background: var(--flowers);
    color: #fff;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    .mini-event-header {
      font-size: 12px;
    }

    .mini-event-item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      font-size: 10px;
      opacity: 50%;
    }
  }

  .mini-events-popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10000;
  }

  .mini-events-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    z-index: 10001;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 95vw;
    .mini-events-popup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      gap: 20px;
      color: var(--darkblue);
      width: 100%;
      .mini-events-popup-header-text {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
        text-transform: uppercase;
      }
      .mini-events-popup-header-close {
        right: 15px;
        top: 15px;
        height: 16px;
        width: 16px;
        cursor: pointer;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .mini-events-items {
      display: flex;
      flex-direction: column;
      padding: 10px 40px 40px 40px;
      gap: 20px;
      color: var(--darkblue);
      max-height: 500px;
      overflow: scroll;
      .mini-events-popup-item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 5px;
        position: relative;;
        min-width: 300px;
        gap:10px;
        box-shadow: 0px 4px 30px 0px #00000014;
        .mini-events-popup-item-icons {
            position: absolute;
            display: flex;
            top: 10px;
            right: 10px;
            .mini-events-popup-item-icon{
                cursor: pointer;
                margin-left: 10px;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .mini-events-popup-item-header {
            display: flex;
            flex-direction: column;
            .mini-events-popup-item-title {
                font-size: 21px;
                line-height: .9;
            }
            .mini-events-popup-item-subtitle {
                font-size: 14px;
                text-transform: uppercase;
                opacity: 50%;
            }
            .mini-events-popup-item-description {
                font-size: 12px;
                opacity: 50%;
            }
        }
        .mini-events-popup-item-body{
            display: flex;
            flex-direction: column;
            .mini-events-popup-item-status {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                text-transform: uppercase;
                .mini-events-popup-item-status-color{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    &.nature {
                        background-color: var(--nature);
                    }
                    &.flowers {
                        background-color: var(--flowers);
                    }
                    &.ocean {
                        background-color: var(--ocean);
                    }
                    &.sun {
                        background-color: var(--sun);
                    }
                    &.sunset {
                        background-color: var(--sunset);
                    }
                    &.gray {
                        background-color: #6c757d;
                    }
                }
            }
            .confirm-price {
                color : #79D2CC;
                text-decoration: underline;
            }
            .confirm-price-popup {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
                padding: 40px;
                border-radius: 5px;
                box-shadow: 0px 4px 30px 0px #00000014;
                display: flex;
                gap: 10px;
                width: 300px;
                margin-bottom: 30px;
                flex-direction: column;
                align-items: center;
                .confirm-price-popup-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-size: 18px;
                    width: 100%;
                    margin-bottom: 10px;
                }
                .confirm-price-popup-body {
                    margin-bottom: 10px;
                    input {
                        border: 0;
                        box-shadow: 0px 4px 30px 0px #00000014;
                        padding: 10px;
                        width: 100%;
                    }
                }
            }
            .mini-events-popup-item-price {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .mini-events-popup-item-footer{
            .mini-events-popup-item-group-info {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                position: relative;
                .patient-list {
                    .patient-list-see-all {
                        background-color: var(--sun);
                        width: fit-content;
                        padding: 0px 5px;
                        border-radius: 5px;
                        text-transform: uppercase;
                        font-size: 10px;
                    }
                    .patient-list-popup {
                        position: absolute;
                        left: 0;
                        right: 0;
                        background: #fff;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0px 4px 30px 0px #00000014;
                        display: flex;
                        gap: 10px;
                        margin-bottom: 30px;
                        flex-direction: column;
                        z-index: 100;
                        .patient-list-popup-close {
                            width: 10px;
                            height: 10px;
                            align-self: flex-end;
                            margin-bottom: 10px;
                        }
                        .patient-list-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;
                            .patient-list-item-image {
                                img{
                                    border-radius: 50%;
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .patient-list-item-name {
                                flex-grow: 1;
                            }
                            .patient-list-item-icons {
                                display: flex;
                                flex-direction: row;
                                gap: 5px;
                                img {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }
                }
            }
            .mini-events-item-action {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;
                .btn-cancel {
                    margin-left: auto;
                }
                .btn-pay {
                    background-color: var(--ocean);
                    color: #fff;
                    a {
                        color: #fff;
                    }
                }
                .choose-location {
                    position: relative;
                    .propose-appointment {
                        display: flex;
                        flex-direction: row;
                        gap:10px;
                    }

                    .popup-choose-location {
                        position: absolute;
                        top: 120%;
                        left: 0;
                        width: max-content;
                        background: #fff;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0px 4px 30px 0px #00000014;
                        display: flex;
                        gap: 10px;
                        margin-bottom: 30px;
                        flex-direction: column;
                        z-index: 10002;
                        .popup-location {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            color: var(--darkblue);
                        }
                    }
                }
            }
        }
      }
    }
  }