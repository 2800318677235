.forgot_password {
    .user-form-container {
        position: relative;
        padding: 80px 0;
        margin: 0 auto;
    }

    @include phone-bp  {
        
    }
}