.fa2_page {

    .authentication-title {
        font-size: 54px;
        line-height: 110%;
        margin-bottom: 30px;
    }
    
    .authentication-description {
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 140%;
        width: 850px;
        margin: 0 auto;
        color: #A1A5A8;
        @include tablet-small-bp {
            width: auto;
        }
    }
    
    .input_section {
        justify-content: center;
        display: flex;
        gap: 40px;
        margin-top: 30px;
        margin-bottom: 80px;
        @include phone-bp {
         gap: 10px;
        }
        input {
            width: 90px;
            height: 173px;
            font-size: 54px;
            text-align: center;
            border-radius: 20px;
            border: 2px solid #D4D4D4;
            background-color: transparent;
            &:hover {
                border: 2px solid #79D2CC;
            }
        }
    }
    
    .share-btn-container {
        button {
            width: 450px;
        }
    }

    .invalid_code {
        p {
            color: red;
        }
    }

}
