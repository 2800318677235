.online_patient_payment {
    padding-top: 80px;

    .user {
        margin: 0 auto;
    }
}


.year_dropdown, .month_dropdown {
    position: absolute;
    top: 0;
    left: 50px;
    background-color: white;
    padding: 20px;
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    z-index: 1;
}