.doctor_settings_page {

    .users-box {
        position: relative;
        margin: 0 -10px;
        margin-top: -70px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
    }
    .user-register-type {
        position: relative;
        margin: 0 15px;
        padding: 15px 18px;
        flex: 0 1 225px;
        max-width: 225px;
        border-radius: 20px;
        background-color: #fff;
        transition: box-shadow 0.3s ease 0s;
    }

    @include phone-bp  {

        h1 {
            font-size: 54px;
        }
        .user-register-type {
            margin: 20px;
        }

    }
}