.register_general {
    .page-hero {
        position: relative;
        background-color: #fff;
      }    
      .page-info {
        position: relative;
        padding: 0px 0px  115px 0px;
        margin: 0 auto;
      }
      
      .page-info .page-text {
        word-break: break-word;
        text-align: center;
      }
      .users-box {
        position: relative;
        margin: 0 -10px;
        margin-top: -70px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
      }
      
      .user-register-type {
        position: relative;
        margin: 0 15px;
        padding: 15px 18px;
        flex: 0 1 225px;
        max-width: 225px;
        border-radius: 20px;
        background-color: #fff;
        transition: box-shadow 0.3s ease 0s;
      }
      
      .user-register-type:hover {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      }
      
      .user-register-type .user-register-label {
        margin-bottom: 15px;
      }
      
      h4 {
        font-size: 21px;
        font-weight: 500;
        color: #000;
      }
      
      p {
        color: #999999;
              }
      .desc {
        margin: 0 auto;
        width: 421px;
        font-size: 16px;
        color: #000;
      }
      
      .user-register-type {
        color: #000;
      }
      
      .user-register-label {
        padding: 9px 13px;
      }

      @include phone-bp  {
        h1 {
            font-size: 54px;
        }
        .users-box {
            grid-template-columns: repeat(2, 1fr);
        }
        .user-register-type {
            margin-bottom: 30px;
        }
      }
}
