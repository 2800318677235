.doctor_book_aapointment_modal {

    .doctors-container {
        margin-top: 31px;
    }

    .job-tags span {
        padding: 0px 7px;
        border-radius: 4px;
        color: #1F1F1F;
    }
    .desc {
        margin: 0;
    }

    .third_party_info {
        position: relative;
        padding-left: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        font-size: 14px;
        margin: 20px 0;
        text-transform: uppercase;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            max-width: 31px;
            width: 100%;
            height: 24px;
            background-image: url(../../assets/img/icons/third-party-payment.svg);
            background-size: 24px 24px;
            background-repeat: no-repeat;
            }
    }
        
    select {
        border: 1px solid #999999;
        border-radius: 4px;
        padding: 4px;
    }
    
    @include phone-bp  {
        
        .desc {
                padding-bottom: 20px;
            }
    }

}