.register_patient {
    
  .user-form-container {
    position: relative;
    padding: 80px 0;
    margin: 0 auto;
}
      @include phone-bp  {
        .user-form {
          max-width: 70%;
          margin: 0 auto;
          .btn {
            max-width: 80%;
            margin: 0 auto;
            display: block;
          }
        }
        
      }
}
