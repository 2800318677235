.doctor_patient_list {

    .user_btn {
        text-align: center;
        width: 100%;
    }
    p {
        height: 20px;
        overflow: hidden;
    }
    .row a {
        width: 50%;
    }

    @include tablet-bp  {
        .row a {
            width: 100%;
        }
    }
}