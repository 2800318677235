
/* ==========================================================================
   Forms
   ========================================================================== */
   .form-control {
    padding: 10px 0.75rem;
    font-size: 12px;
    border-color: #fff;
    border-radius: 5px;
    background: #fff;
  }

  .StripeElement {
    // display: block;
    // margin: 10px 0 20px 0;
    // max-width: 500px;
    // padding: 10px 14px;
    // font-size: 1em;
    // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    //   rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    // border: 0;
    // outline: 0;
    // border-radius: 4px;
    // background: white;

    @extend .form-control;
  }
  
  .form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: var(--ocean);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--button-ocean), 0.1);
  }
  
  .form-label {
    margin-bottom: 5px;
    font-size: 16px;
    color: var(--darkblue);
    flex-grow: 1;
  }
  
  .form-control::-moz-placeholder {
    font-size: 12px;
    color: #a1a5a8;
  }
  
  .form-control::-webkit-input-placeholder {
    font-size: 12px;
    color: #a1a5a8;
  }
  
  .form-control:-ms-input-placeholder {
    font-size: 12px;
    color: #a1a5a8;
  }
  
  .form-control::-ms-input-placeholder {
    font-size: 12px;
    color: #a1a5a8;
  }
  
  .form-control::placeholder {
    font-size: 12px;
    color: #a1a5a8;
  }
  
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
  }
  
  .form-text {
    display: none;
    margin-top: 10px;
    font-size: 12px;
    color: #a1a5a8;
  }
  
  .form-text.error {
    display: block;
  }
  
  .form-check-input {
    margin-top: 6px;
    border-color: #f1f1f1;
    border-radius: 2px;
    background-color: #fef9f4;
  }
  
  .form-check-input:checked[type="checkbox"] {
    border-color: var(--nature);
    background-color: var(--nature);
  }
  
  .form-check-input:focus {
    border-color: var(--nature);
    box-shadow: none;
  }
  
  .form-check-label {
    font-size: 12px;
  }
  
  /* Check switcher */
  .notification-checkbox-custom {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: center;
  }
  
  .notification-checkbox-custom > label {
    margin-right: 15px;
  }
  
  .form-switch.from-switch-text-left {
    position: relative;
    padding-left: 0;
    float: right;
  }
  
  .form-switch.from-switch-text-left .form-check-input {
    width: 68px;
    height: 30px;
    margin-left: 0;
    float: none;
  }
  
  .form-switch.from-switch-text-left .form-check-label {
    margin-right: 5px;
    font-size: 16px;
    color: #999;
    text-decoration: underline;
  }
  
  .form-switch .slider .on,
  .form-switch .slider .off {
    position: absolute;
    top: 13px;
    left: 12px;
    font-size: 10px;
    color: #000;
  }
  
  .form-switch .slider .off {
    left: auto;
    right: 12px;
  }
  
  .form-switch .slider .on {
    display: none;
  }
  
  .form-switch .form-check-input:checked + .slider .on {
    display: block;
    color: #fff;
  }
  
  .form-switch .form-check-input:checked + .slider .off {
    display: none;
  }
  
  .form-switch .form-check-input:checked[type="checkbox"] {
    border-color: var(--darkblue);
    background-color: var(--darkblue);
  }
  
  .form-switch .form-check-input:checked:after,
  .form-switch .form-check-input:checked:active:after {
    content: "";
    position: absolute;
    top: 16px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: var(--nature);
  
    border-radius: 100%;
  }
  
  .form-switch .form-check-input:checked:active:after {
    top: 9px;
    right: 9px;
  }
  
  .form-switch .form-check-input:focus {
    border-color: var(--darkblue);
  }
  
  /* Select */
  .form-select {
    padding: 10px 0.75rem;
    font-size: 12px;
    color: #a1a5a8;
    border-color: #fff;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .form-select:focus {
    color: #000;
    background-color: #fff;
    border-color: var(--ocean);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--button-ocean), 0.1);
  }
  
  /* Radio */
  .form-check-input[type="radio"] {
    border-radius: 50% !important;
    background-color: #999;
  }
  
  .form-check-input:checked[type="radio"] {
    border-color: var(--darkblue);
    background-color: var(--darkblue);
    background-image: url(../../assets/img/check-solid.svg);
    background-image: url(../../assets/img/icons/third-party-payment.svg);
    background-size: 8px 10px;
  }
  
  /* Tags input */
  .tagin-wrapper {
    position: relative;
  }
  
  .tagin-wrapper .tagin-tag {
    position: absolute;
    top: 5px;
    left: 5px;
    margin: 2px;
    padding: 4px;
    font-size: 12px;
    color: #fff;
    transition: transform 0.1s;
    border-radius: 4px;
    background-color: var(--nature);
  }
  
  .tagin-wrapper .tagin-tag:empty {
    display: none;
  }
  
  /* Drop Image */
  .drop-image {
    position: relative;
    min-height: 140px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    color: #a1a5a8;
    border: 1px dashed #999;
  }
  
  /* DropDown */
  .dropdown-menu {
    border-color: #fff;
    background: #fff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  }
  
  .dropdown-item:focus,
  .dropdown-item:hover,
  .dropdown-item.active,
  .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #d4d4d4;
  }
  
  .dropdown-toggle.show::after {
    content: "";
    border-bottom: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-top: 0;
    border-left: 0.3em solid transparent;
  }
  