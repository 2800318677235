.doctor_single_card {
  .job-tags span {
    padding: 0px 7px;
    border-radius: 4px;
    color: #1f1f1f;
    margin-bottom: 0.5rem;
    &:nth-child(2) {
      margin-left: 0.5rem;
    }
  }
  .consultation_btn {
    position: absolute;
    left: 14px;
    top: 149px;
  }
  .desc {
    margin: 0;
  }
  select {
    border: 1px solid #999999;
    border-radius: 4px;
    padding: 4px;
    width: 150px;
  }
  .third_party_info {
    position: relative;
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-size: 14px;
    margin: 20px 0;
    text-transform: uppercase;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      max-width: 31px;
      width: 100%;
      height: 24px;
      background-image: url(../../assets/img/icons/third-party-payment.svg);
      background-size: 24px 24px;
      background-repeat: no-repeat;
    }
  }

  @include phone-bp {
    display: block !important;

    .doctor-info {
      display: block !important;
    }
    .job-tags {
      justify-content: normal;
      margin-right: 0.5rem;
    }
    .job-tags span {
      float: left;
      margin-right: 0.5rem;
      &:nth-child(2) {
        margin-left: 0rem;
      }
    }

    .desc {
      padding-bottom: 20px;
      padding-top: 10px;
      clear: left;
    }
    .profile-image {
      float: left;
    }
  }
}
.doctor-book {
  @include phone-bp {
    overflow: hidden;
    .slide-calendar {
      top: -4px;
    }
    .day {
      font-size: 15px !important;
    }
    .date {
      font-size: 12px !important;
    }
  }
}
