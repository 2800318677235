.modal_pop_up {


    .modal-profile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
    }
    
    .profile-info .personal,
    .profile-info .description {
        position: relative;
        margin-bottom: 15px;
    }
    
    .profile-info h3 {
        margin-bottom: 2px;
        font-weight: 500;
    }
    
    .profile-info .personal ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .profile-info .personal ul li {
        padding: 3px 0;
        position: relative;
        color: #999;
    }
    
    .profile-info .personal ul li span {
        position: relative;
    }
    
    .profile-info .personal ul li > span:after {
        content: "";
        position: relative;
        margin: 0 5px;
        display: inline-block;
        width: 4px;
        height: 4px;
        vertical-align: middle;
        border-radius: 100%;
        background: #999;
    }
    
    .profile-info .description p {
        margin: 0;
        color: #999;
    }
    
    .profile-info {
        position: relative;
        width: 100%;
    }
    
    .profile-info .doctor-book-step {
        margin-top: 40px;
        padding: 0;
        border: none;
    }

    .profile-image img {
        display: block;
    }
    .profile-image-wrapper {
        aspect-ratio: none;
    }
    
    .modal-buttons .btn {
        margin-right: 10px;
    }
    .meeting_link {
        color: var(--ocean);
        margin-right: 50px;
        cursor: pointer;
    }

    @include phone-bp  {

        .modal-profile {
            display: block;
            padding-bottom: 15px;
        }
        .modal-buttons .btn {
            margin-right: 0px;
        }

    }
}