.health_overview_page {
  .health-overview-card {
    border-radius: 10px;
    margin: 10px 0;
    height: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .health-overview-card .health_title {
    color: var(--flowers);
    padding-top: 10%;
  }

  .health-overview-card .result {
    padding: 14px;
    color: #909090;
  }
  .health-overview-card h3 {
    margin: 0;
    padding:14px 14px 0px 14px;
    flex-grow: 1;
    overflow: hidden;
  }

  .b-none {
    width: 165px;
    margin-left: 10px;
    padding: 0;
  }
  .fa-edit {
    margin-left: 14px;
    position: absolute;
    bottom: 6px;
    left: 8px;
  }

  @include phone-bp {
  }

  #small-calendar {
    z-index: 99;
  }
}
