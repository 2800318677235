.chat_page {
    .chat_number {
        background: var(--sunset);
        color: white;
        width: 23px;
        height: 23px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 18px;
        right: 5px;
        font-weight: bold;
        font-size: 15px;
        padding: 4px;
    }
    

    @include phone-bp  {
      .chat-send {
        position: absolute;
        right: 12px;
        height: 40px;
      }
      .send_abs {
          top: 10px;
          left: 10px;
      }
    }
}

// .chat_number {
//     background: var(--sunset);
//     color: white;
//     width: 23px;
//     height: 23px;
//     text-align: center;
//     border-radius: 50%;
//     position: absolute;
//     top: 0px;
//     right: -230px;
//     font-weight: bold;
//     font-size: 15px;
//     padding: 4px;

    
// }

.chat-container > div {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    @include phone-bp  {
      padding: 30px 5px;
    }
  }
  
  .chat-container {
    display: flex;
    flex-direction: row;
  }
  
  .group-chat-image {
    width: 30%;
    margin-right: 16px;
  }
  
  .group-chat-content {
    width: 70%;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  
  .group-content {
    max-height: 700px;
    height: 100%;
    margin-bottom: 50px;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  /* .group-content::-webkit-scrollbar {
    display: none;
    overflow: -moz-scrollbars-none;
  } */
  
  @media (max-width: 992px) {
    .chat-container {
      flex-wrap: wrap;
    }
  
    .group-chat-content_img {
      display: none;
    }
  
    .group-chat-image,
    .group-chat-content {
      width: 100%;
      margin-right: 0px;
      margin-top: 20px;
    }
  }
  
  .chat-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 20px!important;
    position: relative;
  }
  
  .chat-item-active {
    background: #f3f3f3;
  }
  
  .chat-item_image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.08));
    border-radius: 4px;
  }
  
  .chat-item-info {
    margin-left: 35px;
    border-bottom: 1px solid #d4d4d4;
  }
  
  .chat-item-info h3 {
    font-weight: 400;
    font-size: 21px;
    line-height: 140%;
  }
  
  .chat-item-info p {
    color: #999999;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
  
  .chat-customer {
    background: #f3f3f3;
    border-radius: 10px;
    padding: 19px 18px 39px 17px;
    max-width: 290px;
    width: 100%;
    margin-bottom: 14px;
  }
  .chat-doctor {
    padding: 36px 30px 36px 31px;
    background: #10213e;
    border-radius: 10px;
    color: white;
    max-width: 290px;
    width: 100%;
    text-align: center;
    margin-bottom: 14px;
  }
  
  .chat-doctor-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  
  
  .chat_input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    position: absolute;
    bottom: 21px;
    left: 21px;
    right: 21px;
  }
  
  .chat_input {
    width: 85%;
  }
  
  .chat_input input {
    background: #f3f3f3;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 17px;
    width: 100%;
  }
  
  .chat-send {
    width: 15%;
    position: relative;
    justify-content: center;
    display: flex;
  }
  
  .send_abs {
    position: absolute;
    top: 20px; 
  }
  