.admin_pages_wrapper {
  
    $bw: 3px;  // border width
    
    
    /* Tables
    ================================== */
    .Rtable {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 3em 0;
      padding: 0;
    }
    .Rtable-cell {
      box-sizing: border-box;
      flex-grow: 1;
      width: 100%;  // Default to full width
      padding: 0.8em 1.2em;
      overflow: hidden; // Or flex might break
      list-style: none;
      border: solid $bw white;
      background: fade(slategrey,20%);
      > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
    }
    
    /* Table column sizing
    ================================== */
    .Rtable--2cols > .Rtable-cell  { width: 50%; }
    .Rtable--3cols > .Rtable-cell  { width: 33.33%; }
    .Rtable--4cols > .Rtable-cell  { width: 25%; }
    .Rtable--5cols > .Rtable-cell  { width: 20%; }
    .Rtable--6cols > .Rtable-cell  { width: 16.6%; }
    .Rtable--7cols > .Rtable-cell  { width: 14.2%; }
    .Rtable--8cols > .Rtable-cell  { width: 12.5%; }
    .Rtable--9cols > .Rtable-cell  { width: 11.1%; }
    
    
    
    
    
    
    
    
    /* Page styling
    ================================== */
    @import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,700);
    html { 
      height: 100%;
      background-color: #EEE; 
    }
    body {
      box-sizing: border-box;
      min-height: 100%;
      margin: 0 auto;
      padding: 2em;
      max-width: 800px;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 1.2em;
      background-color: white;
      border: double 3px #DDD;
      border-top: none; border-bottom: none;
    }
    h1, h2, h3, h4, h5, h6 { margin-top: 0; }
    h3 { font-size: 1.2em; }
    h4 { font-size: 1em; }
    strong { color: darken(slategrey,20%); }
    









}