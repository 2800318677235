.contact_page {

  .legal_wrapper {
      padding: 180px 0 300px 0;
      background-image: url("../../assets/header/left-yellow.svg"), url("../../assets/contact/contact.png");
      background-position: 0 0px, 97% 130px;
      background-repeat: no-repeat;
  }
  .user-text {
    h2, p {
      text-align: left;
      width: 100%;
    }
    
  }
  .user-form-container {
    max-width: 340px;
  }
 
  @include laptop-small-bp {
      .legal_wrapper {
        background-image: url("../../assets/header/left-yellow.svg"), none;
      }
      .user-text {
        text-align: center;
        width: 100%;
      }
    }

  @include phone-bp  {
  
    .legal_wrapper {
      padding: 60px 0 300px 0;
      background-position: -82px -22px, 136% 100%;
    }
    h2 {
      text-align: center;
    }
    .content_wrapper {
      margin-bottom: 50px;
    }
    
  }
}
