.register_doctor {
 
    padding: 80px 0;

    .third_party_buttons {
        display: flex;
    }
    .form-check {
        margin-right: 30px;
    }
    @include phone-bp  {

    }
}