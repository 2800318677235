@import "styles/main";

body {
  margin: 0;
  position: relative;
  font-family: "Satoshi-Variable", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background: var(--main-bg-color);
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::selection {
  background: #000;
  color: #fff;
}
::-moz-selection {
  background: #000;
  color: #fff;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background: #000000cc;
  border-radius: 10px;
  width: 6px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000cc;
  border-radius: 10px;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #fff;
}
.b-none {
  border: none;
  background-color: transparent;
}

.cp {
  cursor: pointer;
}

.jcarnd {
  justify-content: space-around;
}

.loading_bg-modal {
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.save-disabled {
  background: gray !important;
}
