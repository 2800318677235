.doctor_working_hours {

    .add_more {
        display: block;
        margin: 0 auto;
        border: none;
    }
    @include phone-bp  {

    }
}