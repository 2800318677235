.payment-screen {
  position: relative;
}

.payment-left-vector {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.payment-right-vector {
    position: absolute;
    right: 0;
    top: 50%;
    display: none;
}


.payment-container {
  display: flex;
  justify-content: center;
}

.payment-order {
  background-color: white;
  padding: 28px 60px;
  max-width: 576px;
  margin-top: 40px;
  width: 100%;
  height: auto;
}

.payment-container-details {
    display: flex;
    flex-direction: column;
}

.payment-form {
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 630px;
    width: 100%;
    margin: 0 auto;
}

.payment-form input {
    padding: 30px;
    background: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 30px;
}

.payment-ccv-month {
    justify-content: space-between;
    display: flex;
}


@media(min-width: 992px) {
    .payment-left-vector, .payment-right-vector  {
        display: block;
    }
}


@media(max-width: 768px) {
    .payment-ccv-month {
        flex-direction: column;
    }
    .payment-screen {
        padding: 0 10px;
    }
}