.legal_page {

  .legal_wrapper {
      padding: 180px 0 300px 0;
      background-image: url("../../assets/header/left-yellow.svg"), url("../../assets/header/right-red.svg");
      background-position: 0 0px, 100% 600px;
      background-repeat: no-repeat;
  }
  .content_wrapper {
    margin-top: 50px;
  }
 
  @include phone-bp  {
  
    .legal_wrapper {
      padding: 60px 0 300px 0;
      background-position: -82px -22px, 136% 100%;
    }
    h2 {
      text-align: center;
    }
    .content_wrapper {
      margin-bottom: 50px;
    }
    
  }
}
