@import "./mixins";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #000;
}
span {
  @include Satoshi(400);
}

p {
  @include Satoshi(400);
  font-size: 14px;
  line-height: 110%;
  &.normal {
    font-size: 16px;
   }
   &.light {
    color: #999999;

   }
}

h1 {
  @include Satoshi(400);
  font-size: 84px;
  line-height: 100%;
}

h2 {
  @include Satoshi(400);
  font-size: 54px;
  line-height: 110%;
}

h3 {
  @include Satoshi(600);
  font-size: 21px;
  line-height: 110%;

  &.normal {
    @include Satoshi(400);
  }
}

h4 {
  @include Satoshi(600);
  font-size: 20px;
  line-height: 28px;
}

h5 {
  @include Satoshi(600);
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}
