@import "../mixins";


/* ==========================================================================
   Header
   ========================================================================== */
.user-login-mobile {
  .user-login-mobile-profile-picture {
    width: 42px;
    height: 42px;
    padding: 6px;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
}

header.header {
  position: relative;
  padding-bottom: 86px;
  @include tablet-small-bp {
    padding-bottom: 120px;
  }
}

header.header .navbar {
  padding: 17px 0;
  min-height: 86px;
  background: #fff;
  @include tablet-small-bp {
    padding: 30px 0;
    min-height: 120px;
  }
}

header.header .navbar .navbar-brand {
  flex: 1;
  text-align: center;
  margin-right: 0;

  > img {
    @include customMedia("350px") {
      width: 120px;
    }
  }
}

header.header .navbar .container-fluid {
  position: relative;
}

header.header .navbar-light .navbar-toggler {
  padding-left: 0;
  border: none;
}

header.header .navbar-light .navbar-toggler:focus {
  box-shadow: none;
  outline: 0;
}

header.header .navbar-light .navbar-toggler .b-menu {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  width: 40px;
  height: 20px;
  user-select: none;
  background: transparent;
  cursor: pointer;
  z-index: 12;
  transition: all 0.4s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

header.header .navbar-light .navbar-toggler .b-menu:focus {
  outline: none;
}

header.header .navbar-light .navbar-toggler .b-bun {
  position: relative;
  background: #10213E;
  transition: all 0.4s ease;
}

header.header .navbar-light .navbar-toggler .b-bun-top {
  width: 30px;
  height: 2px;
  top: 0;
}

header.header .navbar-light .navbar-toggler .b-bun-mid {
  width: 30px;
  height: 2px;
  top: 8px;
}

header.header .navbar-light .navbar-toggler .b-bun-bottom {
  width: 20px;
  height: 2px;
  top: 16px;
}

header.header .navbar-light .navbar-collapse {
  position: absolute;
  max-width: 230px;
  width: 100%;
  top: 68px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #10213E;
  z-index: 1001;
}

header.header .navbar-light .navbar-collapse ul li a {
  color: #a1a5a8;
  border-radius: 0;
}

header.header .navbar-light .navbar-collapse ul li a.nav-link.active,
header.header .navbar-light .navbar-collapse ul li a.nav-link:hover,
header.header .navbar-light .navbar-collapse ul li a.nav-link:focus,
header.header .navbar-light .navbar-collapse ul li a.show > .nav-link {
  color: #fff;
  background: none;
  cursor: pointer;
}

header.header .navbar-light .user-login-mobile .dropdown-toggle {
  padding: 6px 11px;
}

header.header .navbar-light .user-login-mobile .dropdown-toggle::after {
  display: none;
}

header.header .navbar-light .user-login-mobile .dropdown-menu {
  padding: 0;
  text-align: center;
  overflow: hidden;
  margin-top: 8px;
  right: 0;
  > li {
    > a {
      cursor: pointer;
    }
  }
}

header.header .navbar-light .user-login-mobile .dropdown-item:focus,
header.header .navbar-light .user-login-mobile .dropdown-item:hover,
header.header .navbar-light .user-login-mobile .dropdown-item.active,
header.header .navbar-light .user-login-mobile .dropdown-item:active {
  color: #fff;
  background: var(--darkblue);
}

header.header .navbar-light .user-login .btn {
  position: relative;
}

header.header .navbar-light .user-login .notification-count {
  top: -7px;
  right: -8px;
}

header.header .navbar-light .user-login .header-search {
  padding: 8px 10px;
  color: var(--darkblue);
}
.header {
  .notifications_icons {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin-right: 10px;
    height: 27px;
  }
  .offer-icon {
     position: relative;
      width: 30px;
      height: 30px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: var(--darkblue);
      margin-right: 5px;
  }
  .chat {
    background: var(--ocean);
  }
  .notification_count {
    position: absolute;
    background: red;
    color: white;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    top: -8px;
    left: 19px;
  }

  .subscription_message {
    background-color: #ec5c78;
    color: white;
    text-align: center;
    display: block;
    width: 100%;
    height: auto;
    top: 86px;
    position: fixed;
    padding: 5px;
  }

  @include phone-bp  {
    .notifications_icons {
      position: absolute;
      right: 3px;
      bottom: -16px;
    }

    .subscription_message {
      top: 88px;
    }
    
  }
}