.propose-different-payment-container {
    display: flex;
    flex-direction: column; 
  }

  
.propose-different-payment {
    z-index: 1056;
    padding: 20px;
    width: 300px;
    background-color: var(--ocean);
    @include center;
    position: fixed;

    input, textarea {
        width: 250px;
    }
  }