.document_share_page {



    .document_share_card {

    }




    @include phone-bp  {
.notification-button {
    width: auto;
}

        .notification-item {
            justify-content: space-between;
        }
    }


}