.iframe_wrapper {
    
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
             .responsive-iframe {
       
    }

    .btn-iframe {
        color: #000;
        opacity: 1;
        display: none;
    }
  
  @include phone-bp  {

    }

}