// fonts
@mixin Satoshi($weight) {
  font-family: 'Satoshi-Variable', sans-serif;
  font-weight: $weight;
}

// media queries
$phone: '576px';
$tabletSmall: '720px';
$tablet: '1024px';
$laptopSmall: '1366px';
$laptop: '1600px';
$desktop: '2000px'; // -- by default
$desktopMedium: '4096px'; // -- 4k
$desktopLarge: '7690px'; // -- 8k

@mixin phone-bp {
  @media (max-width: $phone) {
    @content;
  }
}
@mixin tablet-small-bp {
  @media (max-width: $tabletSmall) {
    @content;
  }
}
@mixin tablet-bp {
  @media (max-width: $tablet) {
    @content;
  }
}
@mixin laptop-small-bp {
  @media (max-width: $laptopSmall) {
    @content;
  }
}
@mixin laptop-bp {
  @media (max-width: $laptop) {
    @content;
  }
}
@mixin customMedia($width) {
  @media (max-width: $width) {
    @content;
  }
}
@mixin Hide() {
  opacity: 0;
  display: none;
  visibility: hidden;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ellipsis($width) {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $width;
  white-space: nowrap;
}

@mixin simpleEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}
