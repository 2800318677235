@import "reset";
@import "mixins";
@import "typography";
@import "components/buttons";


//components
@import "components/footer";
@import "components/header";
@import "components/elements";
@import "components/events";
@import "components/modal_pop_up";
@import "components/2fa";
@import "components/form_fields";
@import "components/iframe_wrapper";
@import "components/stripe_form_wrapper";
@import "components/modal_propose_payment_price";
@import "components/second_payment_option";

// pages
@import "pages/homepage";
@import "pages/register_general";
@import "pages/forgot_password";
@import "pages/register_patient";
@import "pages/legal_page";
@import "pages/chat_page";
@import "pages/document_share_page";
@import "pages/admin_pages_wrapper";


// doctor
@import "pages/doctor_register";
@import "pages/doctor_profile";
@import "pages/doctor_public_page";
@import "pages/doctor_settings_page";
@import "pages/doctor_working_hours";
@import "pages/doctor_patient_list";
@import "pages/doctor_price_create";
@import "pages/doctor_book_aapointment_modal";
@import "pages/online_doctor_payment";
@import "pages/doctor_single_card";
@import "pages/doctor_payment_plan_page";

// patient
@import "pages/patient_profile_page";
@import "pages/patient_dashboard";
@import "pages/patient_card";
@import "pages/online_patient_payment";
@import "pages/stripe_patient_payment_form";

@import "pages/contact_page";
@import "pages/calendar_page";
@import "pages/booking_appointment";
@import "pages/health_overview";






.loading_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #FEF9F4;
  color: #000;
  font-size: 20px;
  display: flex;
  place-content: center;
  align-items: center;
}

.hide {
  display: none;
}