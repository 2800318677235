.patient_card {
    
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    padding: 12px 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    border-radius: 10px;
    background: #fff;

    .user-register-label {
        margin-bottom: 5px;
    }

    .profile-buttons {
        flex: 2;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: stretch;
        align-items: flex-end;
        height: auto;
        padding-top: 25px;
    }

    @include phone-bp  {
        .profile-info {
            width: 100%;
        }
    }
}