.small-calendar-container {
  background: #fff;
  width: 344px;
  padding: 10px 15px 25px 25px;
  height: 100%;
}

.small-calendar_header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.small-calendar-date-selector {
  padding-left: 10px;
  padding-right: 25px;
}

.small-calendar_header_item {
  font-weight: 500;
  width: 30px;
  height: 30px;
  font-size: 13px;
  text-align: center;
}

.small-calendar_body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0px;
}

.small-calendar_item {
  position: relative;
  text-align: center;
  cursor: pointer;
  border: 0.556569px solid #eeeeee;
  width: 30px;
  height: 30px;
  font-size: 13px;
  border-radius: 100%;
  box-shadow: inset 0 0 1px #eee;
  margin-bottom: 10px;
}

.small-calendar_item > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.small-calendar_item-active {
  background: #10213e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
}

.small-calendar_item-inactive {
  color: #909090;
}

.small-calendar_item-active::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  background-color: #ec5c78;
}
