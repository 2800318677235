.stripe_form_wrapper {

    .title {
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }
    .stripe_payment_form{
        width: auto;
    }
    .btn {
        margin-top: 20px;
        min-width: 100px;
    }
   

    @include phone-bp  {
            
   
    }
}