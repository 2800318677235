.public_doctor_page {
    
    .profile-image-wrapper {
        padding-bottom: 0;
        float: left;
    }
    .profile-image img {
        display: block;
        margin: 0 auto;
    }
    .slide-calendar {
        top: -8px;
    }
    .doctor-item {
        margin-top: 30px;
    }
    .doctor-scrool {
        overflow: auto;
        height: 280px; 
      }
      


    .doctor-book .doctor-book-day {
        flex: 1 1 calc(33.333%);
        width: 33.333%;
        height: 255px;
        margin-bottom: 20px;
        &:before {
            height: 255px;    
        }
    }
    
    @include phone-bp  {

    }
}