.doctor_payment_plan_page {

    position: relative;

    .register-plans {
        display: flex;
        justify-content: center;
    }
    .register-plan .register-plan-feature-list {
        height: 180px;
    }
    .register-plan {
        height: 400px;
    }
    .see_credits_btn {
        position: absolute;
        top: 40px;
        right: 20px;
    }
    .one_day_plan {
        margin: 30px 0;
        text-align: center;
        h2 {
            padding: 40px 0;
        }
    }
    .register-plan {
        display: block;
        margin: 0px auto 30px auto;
    }
    @include phone-bp  {
        .register-plan {
            margin-bottom: 30px;
        }
        .see_credits_btn {
            right: 15px;
        }
    }
}