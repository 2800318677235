.home-main_section {
  background-color: #fff;
  width: 100%;
  padding: 62px 15px 32px;
}

.home-main_information,
.home-main_title {
  font-style: normal;
  text-align: center;
}

.home-main_title {
  width: 952px;
  font-size: 84px;
  margin: 0 auto;
}

.home-buttons {
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  margin: auto;
  max-width: 850px;
}

.mobile-landing {
  display: none;
}

.marked {
  position: absolute;
  right: 0;
}

.home-main_information {
  width: 420px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.find_doctor_btn {
  margin-right: 10px;
}

.wht-offer {
  margin-top: 70px;
  margin-bottom: 70px;
}

.offer-modify-container-main {
  max-width: 90%;
}

.wht-offer-container {
  padding-bottom: 400px;
}

.faster-medical-container {
  display: flex;
  flex-direction: row;
}

.faster-medical-container-left {
  padding: 141px 145px;
}

.book_an_appointment_home {
  background: white;
  padding-top: 20px;
  padding-left: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
}

.doctors_speak-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 200px;
  padding-left: 50px;
  padding-right: 50px;
  background: white;
}

.speak-doctors {
  background-color: white;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 50px;
}

.speak-doctors-right img {
  position: absolute;
  top: -66%;
  right: 0%;
}

.speak-doctors-left {
  max-width: 493px;
}

.speak-doctors-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
}

.verified-doctors-speci-des {
  max-width: 600px;
  margin: 0 auto;
}

.speak-doctors_li-active {
  list-style: none;
}

.speak-doctors_li-active li {
  font-size: 20px;
  text-align: center;
  display: inline-block;
}

.speak-doctors_li-active li::before {
  margin-right: 5px;
}

.speak-doctors_li-active li:nth-child(1)::before {
  content: "1.";
  color: #1ea6c4;
}
.speak-doctors_li-active li:nth-child(2)::before {
  content: "2.";
  color: #79d2cc;
}

.speak-doctors_li-active li:nth-child(3)::before {
  content: "3.";
  color: #f5c84f;
}

.speak-doctors_li-active li:nth-child(4)::before {
  content: "4.";
    color: #ec5b78;
}

.run-devices {
  display: flex;
  flex-direction: row;
  padding: 141px 145px;
  justify-content: space-between;
  align-items: center;
}

.run-devices img {
  height: 400px;
}

@media (max-width: 768px) {
  .speak-doctors-right {
    display: none;
  }
  .doctors_speak-container,
  .book_an_appointment_home {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .doctors_speak-container div img {
    width: 100%;
    height: 100%;
  }
  .speak-doctors-subtitle {
    font-size: 40px;
  }
}

@media (max-width: 1300px) {
  .speak-doctors {
    flex-direction: column;
  }

  .run-devices {
    flex-direction: column;
    padding: 0 10px;
  }

  .run-devices img {
    width: 100%;
    height: auto;
  }

  .faster-medical-container {
    display: flex;
    flex-direction: column;
  }

  .faster-medical-container img {
    width: 50%;
  }

  .faster-medical-container-left {
    padding: 20px;
  }

  .wht-offer-container {
    padding-bottom: 120px;
  }

  .verified-doctors-speci-des {
    max-width: 100%;
    padding: 0 10px;
  }

  .doctors_speak-container {
    flex-direction: column;
  }

  .speak-doctors-right img {
    position: relative;
    width: 100%;
    bottom: 0;
    top: 0;
    right: 0;
    height: 100%;
  }
}

@media (max-width: 992px) {
  .home-main_title {
    width: 100%;
    font-size: 54px;
  }
  .marked {
    display: none;
  }

  .home-buttons .find_apply-buttons {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .find_doctor_btn {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .home-main_information {
    width: 100%;
  }

  .faster-medical-container img {
    display: none;
  }

  .speak-doctors {
    padding: 0 10px;
  }

  .offer-modify-container-main {
    max-width: 100%;
  }

  .speak-doctors-left {
    max-width: 100%;
  }

  .speak-doctors_li-active {
    padding-left: 0;
  }

  .speak-doctors_li-active li {
    font-size: 14px;
  }

  .mobile-landing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    padding: 35px;
  }

  .home-landing {
    display: none !important;
  }
}

/* Dashboard cards on hover.. */
.offer:hover {
  background: #4066b7;
}

.run-browser button {
  background-color: #10213e;
  border-radius: 4px;
  padding: 12px 34px 10px 31px;
  color: white;
  border: none;
  margin-bottom: 20px;
}

.pwa {
  position: relative;
  width: 140px;
  cursor: pointer;
}

.pwamainimg {
  width: 120% !important;
  height: auto !important;
}

.pwaimg {
  width: 50px !important;
}

.pwaimg {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 20px !important;
}

/* OVERALL LAYOUT */

.left-yellow {
  position: fixed;
  left: 0;
  top: 80px;
}

.right-red {
  right: 0;
  position: fixed;
  top: 60%;
}

.header-left {
  position: fixed;
  left: 0;
  top: 50%;
}

.header-right {
  position: fixed;
  right: 0;
  top: 10%;
}

.book-your-visit-left-flag {
  position: absolute;
  top: 65%;
  left: 2%;
}

.book-your-visit-right-flag {
  right: 0;
  top: 65%;
  position: absolute;
}

.book-form-container .lfimg {
  position: absolute;
  top: 20%;
}

.book-form-container .rgimg {
  position: absolute;
  right: 0;
  top: 5%;
}

@media (max-width: 1400px) {
  .book-form-container .lfimg,
  .book-form-container .rgimg {
    display: none;
  }
  .header-left,
  .header-right {
    display: none;
  }
  .book-your-visit-left-flag,
  .book-your-visit-right-flag {
    display: none;
  }
  .left-yellow {
    top: 0;
  }

  .right-red {
    right: -20%;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

.fadeOut {
  animation-name: fadeOutAnimation;
  animation-duration: 12000ms;
  animation-fill-mode: forwards;
}

.jumping-notification {
  position: fixed;
  right: 2%;
  bottom: 15%;
  z-index: 2;
}



/* //::-webkit-scrollbar-track-piece */


.advise-box-container {
  display: flex;
  margin: 0 52px;
  gap: 48px;
  flex-direction: row;
}

.advise-box {
  background-color: #fff;
  box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.54);
  display: flex;
  flex: 0 33%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.advise-box--title {
  margin-top: 32px;
  font-size: 21px;
  color: #000;
  text-align: center;
}

.advise-box--subtitle {
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: center;
}

.advise-box--description {
  padding: 59px;
}


.advise-box--header {
  width: 100%;
}

.advise-img {
  margin: 0 auto;
  display: flex;
}

.not_applicable {
  background: #F5C84F;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  top: 25%;
  outline: none;
  border: none;
  padding: 5px 36px;
}

.disable_period {
  opacity: 0.4;
  pointer-events: none;
}


.confirm-content {
  max-width: 495px;
  margin: 0 auto;
}