.calendar_page {
    
        padding: 0 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      
        .calendar_wrapper {
            background: white;
            padding: 20px 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
      .week {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        width: 100%;
        border-radius: 10px;
      }
      
      .calendar-day {
        /* Should be discussed - margin-left: -1px */
        margin-left: -1px;
        position: relative;
        display: flex;
        padding: 0 10px;
        flex-direction: column;
        border: 0.860952px solid #d4d4d4;
        float: left;
        height: 100%;
        min-height: 130px;
        gap: 10px;
        .day-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      
      .day-number-name {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
            &.day-date {
            font-size: 18px;
            }
        }
        .upcomming {
            font-size: 21px;
        }
        .desc {
            font-size: 16px;
        }
      
      .day:nth-child(n + 2) {
        margin-left: -1px;
        margin-bottom: -1px;
      }
      
      .day span {
        width: 100%;
        text-align: end;
      }
      
      .cp {
        cursor: pointer;
      }
      
      .calendar-switcher {
        display: flex;
        justify-content: center;
      }

      .create-appointment-btn {
        margin-bottom: 20px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        .create-appointment-plus {
          width: 30px;
          height: 30px;
          background-color: var(--sunset);
          color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'DM Sans', sans-serif;
          font-size: 21px;
        }
        .create-appointment-text {
          font-size: 16px;
          color: #000;
        }
      }
      
      .create-appointment-popup-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 10000;
      }

      .create-appointment-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 5px;
        padding: 40px;
        z-index: 10001;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 95vw;
        min-width: 300px;
        max-height: 80vh;
        overflow: scroll;
        .create-appointment-popup-header {
          font-size: 21px;
          margin-bottom: 20px;
          font-weight: 400;
          display: flex;
          .create-appointment-popup-header-text {
            display: flex;
            align-items: center;
            gap: 10px;
            &.group {
              color: #fff;
              padding: 5px;
              border-radius: 5px;
              background-color: var(--nature); 
            }
            &.individual {
              color: #fff;
              padding: 5px;
              border-radius: 5px;
              background-color: var(--sunset); 
            }
          }
          .create-appointment-popup-header-close {
            position: absolute;
            right: 15px;
            top: 15px;
            color: var(--darkblue);
            height: 16px;
            width: 16px;
            cursor: pointer;
            display: flex;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .create-appointment-popup-inner{
          input:not([type="radio"]), textarea, select {
            border: #EEEEEE 1px solid;
          }
        }
        .box-popup-container{
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
          .box-popup {
            position: relative;
            padding: 15px 18px;
            max-width: 225px;
            border-radius: 20px;
            background-color: #fff;
            flex: 1 1 115px;
            box-shadow: 0px 4px 30px 0px #00000014;
            .box-popup-icon {
              position: relative;
              margin-bottom: 16px;
              padding: 13px;
              display: inline-block;
              color: #fff;
              text-decoration: none;
              border-radius: 4px;
            }
            .sunset{
              background: var(--sunset);
            }
            .nature{
              background: var(--nature);
            }
            .box-popup-description{
              p {
                color: #999999;
              }
            }
          }
        }
      }

      .month-title {
        padding: 0 20px;
      }
      
      .inactive-day {
        color: #999999;
      }
      
      .events {
        position: absolute;
        top: 13%;
        overflow: scroll;
        max-height: 80%;
        overflow-x: hidden;
        box-shadow: 0px 3.44381px 8.60952px rgba(0, 0, 0, 0.08);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        width: 90%;
        left: 4%;
        overflow: scroll;
      }
      
      .events::-webkit-scrollbar {
        display: none;
        overflow: -moz-scrollbars-none;
      }
      
      
      @media (max-width: 800px) {
        .day-number-name {
          display: none;
        }
      }
      
      @media (max-width: 576px) {
        .week {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      
      @media (min-width: 576px) and (max-width: 800px) {
        .week {
          grid-template-columns: repeat(3, 1fr);
        }
        .week {
          height: 400px;
          overflow: auto;
        }
      }

    @include phone-bp  {
        padding: 0 5px;

        .week {
          height: 300px;
          overflow: auto;
        }
    }
      
}